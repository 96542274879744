export const environment = {
  production: false,
  version: '0.2.3.0',
  firebase: {
    apiKey: "AIzaSyDxLCNnAIDJUk_bmrgzLJj7cgLBd-AF_M8",
    authDomain: "uat-web-sitrack-ot.firebaseapp.com",
    projectId: "uat-web-sitrack-ot",
    storageBucket: "uat-web-sitrack-ot.appspot.com",
    messagingSenderId: "178375928402",
    appId: "1:178375928402:web:bce5e77a76857c2f20cd8b"
  },
 /*firebase: {
    apiKey: "AIzaSyCGBnKQJranEMWAQf1w6JpHghMl_A77C7U",
    authDomain: "web-sitrack-ot.firebaseapp.com",
    projectId: "web-sitrack-ot",
    storageBucket: "web-sitrack-ot.appspot.com",
    messagingSenderId: "871362670113",
    appId: "1:871362670113:web:13392befc7b92616acbd60",
    measurementId: "G-G3C5K9VXZM"
  },*/
  sessionID: "session-uid",
  sessionActualUser: "session-name",
  sessionWorkCompany: "session-company",
  sessionUserPosition: "session-position",
  sessionEmployeeSAE: "session-sae",
  sessionAccountBallance: "session-accountbalance",
  orderstatus: [
    'REGISTRADO', 'REVISION', 'APROBADA', 'PENDIENTE', 'CANCELADO', 'RECHAZADA', 'EN FACTURACIÓN', 'FACTURADA'
  ],
  activestatus: [
    'REVISION', 'APROBADA', 'PENDIENTE', 'RECHAZADA', 'EN FACTURACIÓN', 'FACTURADA'
  ],
  invoiceStatus: [
    'FACTURADA', 'PAGO PARCIAL', 'PAGADA', 'ACEPTADA', 'NUEVA', 'PENDIENTE', 'SOLICITADA', 'ERROR FACTURACION', 'CANCELADA'
  ],
  exportValidityStatus: [
    'APROBADA', 'EN FACTURACIÓN', 'FACTURADA'
  ],
  orderInvoiceStatus: [
    'EN FACTURACIÓN', 'FACTURADA'
  ],
  newstatus: [
    'REGISTRADO'
  ],
  cancelstatus: [
    'CANCELADO'
  ],
  sessionCustomer: 'customers',
  sessionProducts: 'products',
  sessionWorkorders: 'workorders',
  sessionConfigsCompany: "session-configsCompany",
  lastblockpreinvoice: 'lastblockpreinvoice',
  //supervisor:'hugo.cisneros@sitrack.com;ricardo.serrano@sitrack.com',
  supervisor: 'apolo@hedhog.com;',
  ccSupervisor: 'apolo@hedhog.com',
  emailTemplate: '<h3>Solicitud de Aprobación</h3><table style="margin-bottom:2em; width: 600px; font-size: 1em;"><tbody><tr><td style="background-color: #0F3352; color: #fff; padding: 0.6em; width: 35%;">Número de orden</td><td style="background-color: #efefef; color: #333; padding: 0.6em;">[[otNumber]]</td></tr><tr><td style="background-color: #174d7c; color: #fff; padding: 0.6em; width: 35%;">Cliente</td><td style="background-color: #fefefe; color: #333; padding: 0.6em;">[[businessName]]</td></tr><tr><td style="background-color: #0F3352; color: #fff; padding: 0.6em; width: 35%;">Solicita</td><td style="background-color: #efefef; color: #333; padding: 0.6em;">[[employeeName]]</td></tr></tbody></table><span style="font-size: 0.9em; color:#666">Notificación del sistema WEB-SITRACK-OT',
  invoiceEmiter: {
    Rfc: 'EKU9003173C9',
    Nombre: 'ESCUELA KEMPER URGATE',
    RegimenFiscal: '603'
  },
  invoiceDummyEmiter: {
    Rfc: 'EKU9003173C9',
    Nombre: 'ESCUELA KEMPER URGATE',
    RegimenFiscal: '603'
  },
  urlHistoricoObtenerOrden: 'https://ms-sitrack-legacy-prd-bvgdgcr42a-uc.a.run.app/v1/legacy/workorder/getOrderById/',
  urlHistoricoObtenerOrdenRFC: 'https://ms-sitrack-legacy-prd-bvgdgcr42a-uc.a.run.app/v1/legacy/workorder/getOrderByIdRfc/',
  urlHistoricoObtenerUnidades: 'https://ms-sitrack-legacy-prd-bvgdgcr42a-uc.a.run.app/v1/legacy/units/getUnitsByIdRfc/',
  urlHistoricoObtenerProductos: 'https://ms-sitrack-legacy-prd-bvgdgcr42a-uc.a.run.app/v1/legacy/products/getProductsByIdRfc/',
  urlHistoricoObtenerPagos: 'https://ms-sitrack-legacy-prd-bvgdgcr42a-uc.a.run.app/v1/legacy/payments/getPaymentsByIdRfc/',
  urlGetHolderById: 'https://web-sitrack-service-holders-bvgdgcr42a-uc.a.run.app/getHolderById?idHolder=',
  //Servicio de verificacion solo debe apuntar a pruebas.
  urlConectiaVerificaDatos: 'https://ms-sitrack-facturacion-dev-bvgdgcr42a-uc.a.run.app/v1/sitrack/invoce/create',
  //Emision y recuperacion CONECTIA
  urlConectiaEmitirFactura: 'https://ms-sitrack-facturacion-dev-bvgdgcr42a-uc.a.run.app/v1/sitrack/invoce/create',
  urlConectiaObtenerDocumento: 'https://ms-sitrack-facturacion-dev-bvgdgcr42a-uc.a.run.app/v1/sitrack/invoce/retrieve/',
  //NO usar estas son del antiguo PAC.
  urlValidaClienteDummyFacturaCF: 'https://ms-sitrack-facturacion-dev-bvgdgcr42a-uc.a.run.app/v1/sitrack/invoce/create',
  //urlEmitirFacturaCF:'https://us-central1-web-sitrack-ot.cloudfunctions.net/emitirFacturaSitrackPRD',
  //urlEmitirFacturaCF:'https://us-central1-web-sitrack-ot.cloudfunctions.net/emitirFacturaSitrackDEV',
  //urlObtenerFacturaCF:'https://us-central1-web-sitrack-ot.cloudfunctions.net/obtenerFacturaSitrackPRD',
  //urlObtenerFacturaCF:'https://us-central1-web-sitrack-ot.cloudfunctions.net/obtenerFacturaSitrackDEV',
  conectiaApiURL: 'https://api.test.sw.com.mx/pdf/v1/api/RegeneratePdf',
  conectiaPrintApiURL: 'https://api.test.sw.com.mx/pdf/v1/api/GeneratePdf',
  authorizationToken: 'T2lYQ0t4L0RHVkR4dHZ5Nkk1VHNEakZ3Y0J4Nk9GODZuRyt4cE1wVm5tbXB3YVZxTHdOdHAwVXY2NTdJb1hkREtXTzE3dk9pMmdMdkFDR2xFWFVPUXpTUm9mTG1ySXdZbFNja3FRa0RlYURqbzdzdlI2UUx1WGJiKzViUWY2dnZGbFloUDJ6RjhFTGF4M1BySnJ4cHF0YjUvbmRyWWpjTkVLN3ppd3RxL0dJPQ.T2lYQ0t4L0RHVkR4dHZ5Nkk1VHNEakZ3Y0J4Nk9GODZuRyt4cE1wVm5tbFlVcU92YUJTZWlHU3pER1kySnlXRTF4alNUS0ZWcUlVS0NhelhqaXdnWTRncklVSWVvZlFZMWNyUjVxYUFxMWFxcStUL1IzdGpHRTJqdS9Zakw2UGQrNzJ3UWh4TVVxb0g3TU5KV0Q2Um5rb2VpQlZibFk2b3JLeURxQmU5TGhudldsdjExeGpvaDBEQVZYWUhWTE5nKzh5MENnVm9MRjNwRE5MU0xuOWtRdTNGMktEajgrSlVtcVNPbWpLSE9hajJCZC9zOFBEOVp3VG9BbFRaMkFsSHl4ZkoxSWlQYnRERi9kTCtaMkhWeHROSmlUemxHbEhHbDBIMEdueTh0ZmtSOHUwMVNaempVNnlDNTRLRzhxNmU5VlpIdlhJVDMyZ2V2aDVvQzNjRW1YUFVJeXdHcmdvUmhBdVhCS0xyYi9hYjc5Mm40RE1GRUc1MGRkcTg2S0dGSUhVMkhKek5GUTZWRTZpWmlrWG5uZnFLUis1RUZCVmlONjM5YXlXRWRuQjdOK1dTMExnQ2pyWTRwTmdUeW1lRkFLL3UwUFh1Rk9xcytPMlZaN2dLUjNCNEo5aWpGZWFPUnJBQmh2QVhrZVpNa0g2TFZialZvOURwbEdocmgvVXA.SG1wPsd0gqgJFXQXZivd_o86L1E0ERiWpTlaE-yvkgQ'
};
