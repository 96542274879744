export class Client {

    id: string = '';
    idPlatform: number = 0;
    clientGroupId: string = '';

    rfc: string = '';
    defaultProfile: boolean = false;
    standarInvoice: boolean = true;
    deactiveByDueValidity: boolean = false;
    isInsuranceCarrier: boolean = false;
    isRecurrent: boolean = false;
    isNewRecord: boolean = false;

    businessName: string = '';
    //Calle y numero
    address: string = '';
    //Numero interior
    intNumber: string = '';
    //Externo
    extNumber: string = '';
    //Colonia
    square: string = '';
    //Delegacion
    city: string = '';
    //Estado
    state: string = '';
    //País
    country: string = '';
    cp: string = '';

    mainEmailContact: string = '';

    contactAdminName?: string;
    contactAdminEmail?: string;
    contactAdminPhone?: string;

    contactOperativeName?: string;
    contactOperativeEmail?: string;
    contactOperativePhone?: string;

    idSaleForce: string = '';
    cveSAE: string = '';
    ctaContable: string = '';

    //INFORMACION DE FACTURACION
    taxSystem: string = '';
    cfdiUsage: string = '';
    payForm: string = '';
    payMethod: string = '';
    daysCreditPeriod: number = 0;

    active: boolean = true;
    validSAT: boolean = false;

    accountBalanceInvoice: number = 0;
    accountBalancePayed: number = 0;
    accountBalancePending: number = 0;
    accountBalanceRefund: number = 0;
    accountBalance:number = 0;

    recordDate: Date = new Date();
    automaticInvoiceBlock: number = 1;
    company:string ='sitrack';
    employeeCxCId:string = '';
    employeeCxCName:string = '';
  RFC: string;

    constructor() {
    }

    static json2Array(jsonArray: any) {
        const objectArray = new Array<Client>();

        jsonArray.forEach(jsonObject => {
            const object = Client.json2Object(jsonObject);
            objectArray.push(object);
        });

        return objectArray;
    }

    static json2Object(jsonObject: any) {
        const object = new Client();

        object.id = jsonObject.id;
        object.rfc = jsonObject.rfc;
        object.businessName = jsonObject.businessName;
        object.address = jsonObject.address;
        object.contactAdminName = jsonObject.contactAdminName;
        object.contactAdminEmail = jsonObject.contactAdminPhone;
        object.contactAdminPhone = jsonObject.contactAdminPhone;
        object.contactOperativeName = jsonObject.contactOperativeName;
        object.contactOperativeEmail = jsonObject.contactOperativeEmail;
        object.contactOperativePhone = jsonObject.contactOperativePhone;
        object.active = jsonObject.active;
        object.isRecurrent = jsonObject.isRecurrent;

        return object;
    }
}
