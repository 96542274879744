import { Injectable } from "@angular/core";
import { ConfigCompanyService } from "../services/config-company.service";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ConfigCompanyUsecase {

    constructor(private _configCompanyService: ConfigCompanyService) {

    }

    ///Obtenemos las configuraciones de compañia y las cargamos dentro del local storage.
    async getAndLoadConfigs() {
        let configs = await this._configCompanyService.getConfigs();
        let configSubs = configs.subscribe(configDocs => {
            console.log(configDocs);
            localStorage.setItem(environment.sessionConfigsCompany, JSON.stringify(configDocs));
            configSubs.unsubscribe();
        })
    }


    async getWorkordersLeads(idCompany: string) {
        console.log('workorderLeads');
        let leads = await this._configCompanyService.getWorkordersLeads(idCompany);
        return leads;
    }

    /**
     * Recuparamos el ultimo valor y asignamos el siguiente.
     * @param idCompany Usando el Identificador de la compañia
     * @returns Siguiente folio para factura.
     */
    async getNextInvoiceFolioLeads(idCompany: string): Promise<any> {
        console.log('invoiceFolioLeads');
        let nextInvoiceFolio = 0;
        return new Promise(async (resolve, reject) => {
            let leads = await this._configCompanyService.getinvoiceFolioLeads(idCompany);
            leads.forEach(led => {
                console.log(led!["last"]);
                nextInvoiceFolio = led!["last"] + 1;
                /// Guardamos el valor obtenido para el siguiente registro
                resolve({
                    preffix: led!["preffix"] ,
                    last: nextInvoiceFolio
                });
            })

        })
    }

    async updateLastInvoiceFolio(companyId: string, last: number) {
        await this._configCompanyService.upsertInvoiceFolioLeads(companyId, last);
    }

}
