import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Product } from '../../model/product';
import { Utils } from '../../shared/utils';
import { ProductPrice } from '../../model/product-price';
import { environment } from '../../../environments/environment';
import { ProductInvoiceconfig } from '../../model/product-invoiceconfig';
import { ProductItem } from '../../model/product-item';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  products: Array<Product> = [];

  productsCollectionName = 'products';
  productsPricesCollectionName = 'products-prices';
  pricesCollectionName = 'prices';
  storehouseCollectionName='product-storehouse';

  productInvoiceCollectionName = 'product-invoiceconf';

  constructor(private afs: AngularFirestore) {}

  createId(){
    return this.afs.createId();
  }

  getProductsFS() {
    return this.afs
      .collection<Product>(this.productsCollectionName, (ref) =>
        ref.where('active','==',true).orderBy('group', 'desc').orderBy('concept', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Product;
            data.id = a.payload.doc.id;
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            if (data.inventoryUpdate) {
              data.inventoryUpdate = Utils.timestampToDate(data.inventoryUpdate);
            }
            return { ...data };
          })
        )
      );
  }

  getProductsNPricesFS() {
    return this.afs
      .collection<Product>(this.productsCollectionName, (ref) =>
        ref.orderBy('group', 'desc').orderBy('concept', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Product;
            data.id = a.payload.doc.id;

            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            if (data.inventoryUpdate) {
              data.inventoryUpdate = Utils.timestampToDate(data.inventoryUpdate);
            }
            return { ...data };
          })
        )
      );
  }

  getProductsAndPriceByBSFS(businesType: string) {
    return this.afs
      .collection<Product>(this.productsCollectionName, (ref) =>
        ref.where('active','==',true).orderBy('group', 'desc').orderBy('concept', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as Product;
            data.id = a.payload.doc.id;
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            if (data.inventoryUpdate) {
              data.inventoryUpdate = Utils.timestampToDate(data.inventoryUpdate);
            }
            return { ...data };
          })
        )
      );
  }

  async getProductByIdFS(productId: string) {
    return await this.afs
      .collection<Product>(this.productsCollectionName)
      .doc(productId)
      .snapshotChanges()
      .pipe(
        map((action) => {
          const data = action.payload.data() as Product;
          data.id = data.id;
          if (!data.id) {
            data.id = data.id;
          }
          if (data.inventoryUpdate) {
            data.inventoryUpdate = Utils.timestampToDate(data.inventoryUpdate);
          }
          if(data.addReneworder){
            data.addReneworder = true;
          }
          return { ...data };
        })
      );
  }

  getProductPricesByProductIdFS(productId: string) {
    return this.afs
      .collection<Product>(this.productsCollectionName)
      .doc(productId)
      .collection(this.pricesCollectionName, (ref) =>
        ref.orderBy('businessType', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as ProductPrice;
            data.id = a.payload.doc.id;
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
           
            return { ...data };
          })
        )
      );
  }

  getProductPricesByProductIdC(productId: string) {
    return this.afs
      .collection<ProductPrice>(this.productsPricesCollectionName, ref => ref.where("productId","==",productId).orderBy('businessType', 'asc')
      ).snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as ProductPrice;
            data.id = a.payload.doc.id;
            return { ...data };
          })
        )
      );
  }

  getProductPricesByBusinessTypeFS(productId: string, businessType: string) {
    //console.log(productId+' - '+businessType);
    return this.afs
      .collection<Product>(this.productsCollectionName)
      .doc(productId)
      .collection(this.pricesCollectionName, (ref) =>
        ref.where('businessType', '==', businessType)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as ProductPrice;
            data.id = a.payload.doc.id;
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            return { ...data };
          })
        )
      );
  }

  upsertFS(product: Product) {
    console.log(product);
    if (product.id === 'null') {
      product.id = this.afs.createId();
    }
    return this.afs
      .collection(this.productsCollectionName)
      .doc(product.id)
      .set(
        {
          group: product.group,
          code: product.code ? product.code : '',
          concept: product.concept,
          description: product.description,
          solution: product.solution,
          businessType: product.businessType,
          periodMonth: product.periodMonth,
          baseAmount:product.baseAmount?product.baseAmount:0,
          validCash12: product.validCash12 ? product.validCash12 : false,
          validFinanced12: product.validFinanced12
            ? product.validFinanced12
            : false,
            validFinancedEquipment12: product.validFinancedEquipment12
            ? product.validFinancedEquipment12
            : false,
          validLoan: product.validLoan ? product.validLoan : false,
          validLoan12: product.validLoan12 ? product.validLoan12 : false,
          validLoan24: product.validLoan24 ? product.validLoan24 : false,
          validLoan36: product.validLoan36 ? product.validLoan36 : false,
          active: product.active,
          inventory:product.inventory?product.inventory:0,
          inventoryUpdate:product.inventoryUpdate?product.inventoryUpdate:new Date(),
          addReneworder:product.addReneworder??true,
          prices:product.priceList
        },
        { merge: true }
      ).then(async result=>{
        await this.upsertPriceList(product.id, product.priceList);
      });
    product.priceList.forEach((price) => {
      if (!price.id) {
        price.id = this.afs.createId();
      }
      this.afs
        .collection(this.productsCollectionName)
        .doc(product.id)
        .collection(this.pricesCollectionName)
        .doc(price.id)
        .set(
          {
            businessType: price.businessType,
            periodMonth: price.periodMonth,
            equipmentMin: price.equipmentMin,
            equipmentMax: price.equipmentMax,
            installMin: price.installMin,
            installMax: price.installMax,
            annualSubscriptionMin: price.annualSubscriptionMin,
            annualSubscriptionMax: price.annualSubscriptionMax,
            monthlySubscriptionMin: price.monthlySubscriptionMin,
            monthlySubscriptionMax: price.monthlySubscriptionMax,
            totalMin: price.totalMin,
            totalMax: price.totalMax
          },
          { merge: true }
        );
    });
  }

  upsertPriceList(productId:string, priceList:Array<ProductPrice>){
    let batch = this.afs.firestore.batch();
    priceList.forEach(price=>{
      if(!price.id){
        price.id = this.afs.createId();
      }
       let ref = this.afs.collection(this.productsCollectionName).doc(productId).collection(this.pricesCollectionName).doc(price.id).ref;

       ref.set({
        businessType: price.businessType,
        periodMonth: price.periodMonth,
        equipmentMin: price.equipmentMin,
        equipmentMax: price.equipmentMax,
        installMin: price.installMin,
        installMax: price.installMax,
        annualSubscriptionMin: price.annualSubscriptionMin,
        annualSubscriptionMax: price.annualSubscriptionMax,
        monthlySubscriptionMin: price.monthlySubscriptionMin,
        monthlySubscriptionMax: price.monthlySubscriptionMax,
        totalMin: price.totalMin,
        totalMax: price.totalMax
      },
      { merge: true });
    })
    batch.commit();
  }

  deletePriceFS(productId: string, productPriceId: string) {
    console.log(productId + ' - ' + productPriceId);
    return this.afs
      .collection(this.productsCollectionName)
      .doc(productId)
      .collection(this.pricesCollectionName)
      .doc(productPriceId)
      .delete();
  }

  getProducts(businesType: string) {
    return this.getProductsAndPriceByBSFS(businesType);
  }

  getProductById(productId: string) {
    return this.products.find((product) => product.id === productId);
  }

  upsert(product: Product) {
    if (product.id === '') {
      console.log('Registra');
      product.id = (this.products.length + 1).toString();
      this.products.push(product);
      localStorage.setItem(
        environment.sessionProducts,
        JSON.stringify(this.products)
      );
    } else {
      console.log('Actualización');
      for (let index = 0; index < this.products.length; index++) {
        if (this.products[index].id === product.id) {
          this.products[index] = product;
        }
      }
      localStorage.setItem(
        environment.sessionProducts,
        JSON.stringify(this.products)
      );
    }
  }

  calculatePrice(
    businessType: string,
    equipmentPrice: number,
    installPrice: number,
    annualSubscription: number
  ) {
    if (businessType === 'CONTADO12') {
      return equipmentPrice + installPrice + annualSubscription;
    } else if (businessType === 'FINANCIADO12') {
      return (equipmentPrice + annualSubscription) * 1.05;
    } else if (businessType === 'COMODATO24') {
      return (equipmentPrice + 2 * annualSubscription) * 1.1;
    } else if (businessType === 'COMODATO36') {
      return (equipmentPrice + 3 * annualSubscription) * 1.15;
    } else {
      return 0;
    }
  }

  getProductInvoceByProductId(productId:string){
    return this.afs.collection(this.productInvoiceCollectionName,ref=>
      ref.where('productId','==',productId).orderBy('business','asc').orderBy('businessType','asc').orderBy('concept','asc')
      ) .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            //console.log(a.payload.doc.id);
            const data = a.payload.doc.data() as ProductInvoiceconfig;
            data.id = a.payload.doc.id;
            if (!data.id) {
              data.id = a.payload.doc.id;
            }
            return { ...data };
          })
        )
      );
  }

  upsertProductInvoice(productInvoiceconfig:ProductInvoiceconfig){
    if(!productInvoiceconfig.id){
      productInvoiceconfig.id = this.afs.createId();
    }
    return this.afs.collection(this.productInvoiceCollectionName).doc(productInvoiceconfig.id).set(
      {
        productId:productInvoiceconfig.productId,
        business:productInvoiceconfig.business,
        businessType:productInvoiceconfig.businessType,
        concept:productInvoiceconfig.concept,
        periodMonth:productInvoiceconfig.periodMonth,
        accountable:productInvoiceconfig.accountable,
        cveSAT:productInvoiceconfig.cveSAT,
        unitCveSAT:productInvoiceconfig.unitCveSAT,
      },
      {merge:true}
    ).catch(err=>{
      console.error(err);
    });
  }

  deleteProductInvoiceConfig(configId:string){
    return this.afs.collection(this.productInvoiceCollectionName).doc(configId).delete();
  }

  //METODOS DEL INVENTARIO.

  /**
   * Obtener los registro completos del inventario ordenados por fecha de registro.
   * @returns Ob
   */
  getStorehouseItems(){
    return this.afs.collection<ProductItem>(this.storehouseCollectionName,ref=> ref.orderBy('lastUpdate','desc')).snapshotChanges().pipe(
      map(actions => actions.map( a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as ProductItem;
        data.id = a.payload.doc.id;
        if(data.lastUpdate){
          data.lastUpdate = Utils.timestampToDate(data.lastUpdate);
        }
        if(!data.id){
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  getStorehouseItemsByProductId(productId:string){
    return this.afs.collection<ProductItem>(this.storehouseCollectionName,ref=> ref.where('productId','==',productId).orderBy('lastUpdate','desc')).snapshotChanges().pipe(
      map(actions => actions.map( a => {
        //console.log(a.payload.doc.id);
        const data = a.payload.doc.data() as ProductItem;
        data.id = a.payload.doc.id;
        if(data.lastUpdate){
          data.lastUpdate = Utils.timestampToDate(data.lastUpdate);
        }
        if(!data.id){
          data.id = a.payload.doc.id;
        }
        return { ...data };
      }))
    );
  }

  upsertProductItem(productItem:ProductItem){
    if(!productItem.id){
      productItem.id = this.afs.createId();
    }
    return this.afs.collection(this.storehouseCollectionName).doc(productItem.id).set(
      {
        productId:productItem.productId,
        quantity:productItem.quantity,
        lastUpdate:productItem.lastUpdate,
      },
      {merge:true}
    );
  }

  deleteProductItem(productItemId:string){
    return this.afs.collection(this.storehouseCollectionName).doc(productItemId).delete();
  }
}
